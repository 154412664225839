<template>
  <div>
    <b-card class="mb-0" no-body>
      <div class="m-2">
        <div class="d-md-flex  ">
          <b-input-group  class="input-group-merge col-12 col-md-4 px-0" >
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
                    
                    v-model="filters['search']"
                    id="icons-search"
                    placeholder="Search"
                />
          </b-input-group>

        <div class="align-self-end ml-auto w-10 mt-2 mt-md-0 ">
          <b-button class="btn-block" variant="primary" @click="toggleSideBars()"
          >
            <feather-icon
                    class="mr-50"
                    icon="PlusIcon"/>
            <span class="align-middle">Create Service Clinic</span>
          </b-button>
        </div>
        </div>

        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
          <b-row>
          <b-col class="mt-2">
              <b-table
                id="table"
                ref="table"
                @row-clicked="myRowClickHandler"
                :current-page="currentPage"
                :per-page="pagination.perPage"
                :items="getProgrammeList"
                :selectOnCheckboxOnly="true"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                empty-text="No matching records found"
                :fields="fields"
                show-empty
                primary-key="id"
                class="custom_align mobile_table_css bank-tbl"
                hover
                responsive
              >

              <template #cell(staff)="data">

                <!-- avatar group -->
                <b-avatar-group
                    size="34px"
                >
                  <b-avatar
                      v-for="avatar in [5,7,8,9,10]"
                      :key="avatar.avatar"
                      v-b-tooltip.hover.bottom="avatar.fullName"
                      :src="require(`@/assets/images/portrait/small/avatar-s-${avatar}.jpg`)"
                      class="pull-up"
                  />
                  <h6 class="align-self-center cursor-pointer ml-1 mb-0">
                    +12
                  </h6>
                </b-avatar-group>

              </template>

              <template #cell(status)="data">
                <b-badge

                    :variant="'light-success'"
                    class="text-capitalize"
                >
                  Active
                </b-badge>
              </template>
            </b-table>
          </b-col>
        </b-row>
        </b-overlay>
        <!--                pagination-->
        <div class="mx-1 mb-2">
          <b-row>

            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                cols="12"
                sm="6"
            >
              <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
              <!-- <span class="text-muted">Showing 1 to 10 of 187 entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  class="mb-0 mt-1 mt-sm-0 "
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </div>
    </b-card>

    <AddProgrammeSideBar
        ref="AddProgrammeSideBar"
        :is-add-new-programme-sidebar-active.sync="isAddNewProgrammeSidebarActive"
        @toggleSideBars="toggleSideBars()"
        @dataRefresh="dataRefresh()"/>
  </div>
</template>

<script>
import AddProgrammeSideBar from '@/views/programmes/sidebars/AddProgrammeSideBar'
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import ProgrammesAPI from '@/apis/modules/programmes'

import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mounted } from 'vue-echarts'

export default {
  name: 'program-list',
  components: {
    AddProgrammeSideBar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data: function () {
    return {
      currentPage: 1,
      isAddNewProgrammeSidebarActive: false,
      filters:{},
      filter: null,
      filterOn: [],
      tableLoading: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      fields: [
        {
          key: 'name',
          sortable: true,
          label: 'SERVICE CLINIC NAME'
        },
        {
          key: 'start_end_date',
          sortable: false,
          label: 'START DATE - END DATE'
        },
        {
          key: 'budget',
          sortable: false
        },
      ],
      items: []
    }
  },
  methods: {
    toggleSideBars() {
      this.isAddNewProgrammeSidebarActive = !this.isAddNewProgrammeSidebarActive
    },
    async getProgrammeList(){
      try{
        this.tableLoading = true

        if (this.sortBy === '') {
          this.sortDesc = true
        }
        
        const Response = await ProgrammesAPI.getProgrammesList(this.currentPage, 15, this.filterQuery, this.sortBy, this.sortDesc)

        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
          start_end_date: x.start_date ? this.momentFormat(x.start_date, 'MMM DD, YYYY') + ' - ' +this.momentFormat(x.end_date, 'MMM DD, YYYY') : 'N/A',
          start_date: x.start_date ? this.momentFormat(x.start_date, 'DD-MM-YYYY') : 'N/A',
          end_date: x.end_date ? this.momentFormat(x.end_date, 'DD-MM-YYYY') : 'N/A',
          budget: x.budget ? '£' + x.budget : 'N/A'
        }))

      
        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        this.tableLoading = false

        return dataArray

      }catch(error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    myRowClickHandler(item) {
     this.$router.push(`/programmes/${item.id}`)
    },
    filterQueryUpdate () {
      this.$refs.table.refresh()
    },
    dataRefresh(){
      this.$refs.table.refresh()
      this.isAddNewProgrammeSidebarActive = false
    },
  },

}
</script>

<style scoped>

.form-control{
  height: 2.8rem;
}
</style>
